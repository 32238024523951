import request from "@/utils/request";

// 获取电台列表
export function RadioList(params) {
    return request({
        url: '/admin/japanese_v3/radioList',
        method: 'get',
        params
    })
}

// 添加电台
export function RadioAdd(data) {
    return request({
        url: '/admin/japanese_v3/radioAdd',
        method: 'post',
        data
    })
}

// 修改电台
export function RadioEdit(data) {
    return request({
        url: '/admin/japanese_v3/radioEdit',
        method: 'put',
        data
    })
}

// 删除电台
export function RadioDel(data) {
    return request({
        url: '/admin/japanese_v3/radioDel',
        method: 'delete',
        data
    })
}

// 获取上传COS签名
export function getRadioCosToken(params) {
    return request({
        url: '/admin/japanese_v3/getRadioCosToken',
        method: 'get',
        params
    })
}


// 获取电台小节列表
export function RadioLibList(params) {
    return request({
        url: '/admin/japanese_v3/radioLibList',
        method: 'get',
        params
    })
}

// 添加电台小节
export function RadioLibAdd(data) {
    return request({
        url: '/admin/japanese_v3/radioLibAdd',
        method: 'post',
        data
    })
}


// 修改电台小节
export function RadioLibEdit(data) {
    return request({
        url: '/admin/japanese_v3/radioLibEdit',
        method: 'put',
        data
    })
}

// 删除电台小节
export function RadioLibDel(data) {
    return request({
        url: '/admin/japanese_v3/radioLibDel',
        method: 'delete',
        data
    })
}

// 电台小节上架或下架
export function RadioLibEnable(data) {
    return request({
        url: '/admin/japanese_v3/radioLibEnable',
        method: 'put',
        data
    })
}

// 日语APP电台分类列表
export function RadioTypeList(params) {
    return request({
        url: '/admin/japanese_v3/radioTypeList',
        method: 'get',
        params
    })
}

// 添加日语APP电台分类
export function RadioTypeAdd(data) {
    return request({
        url: '/admin/japanese_v3/radioTypeAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP电台分类
export function RadioTypeEdit(data) {
    return request({
        url: '/admin/japanese_v3/radioTypeEdit',
        method: 'put',
        data
    })
}

// 删除日语APP电台分类
export function RadioTypeDel(data) {
    return request({
        url: '/admin/japanese_v3/radioTypeDel',
        method: 'delete',
        data
    })
}

// 日语APP电台小节待审核列表
export function RadioLibAuditList(params) {
    return request({
        url: '/admin/japanese_v3/radioLibAuditList',
        method: 'get',
        params
    })
}

// 日语APP电台小节审核
export function RadioLibAuditEnable(data) {
    return request({
        url: '/admin/japanese_v3/radioLibAuditEnable',
        method: 'put',
        data
    })
}

// 日语APP电台小节排序
export function RadioLibOrder(data) {
    return request({
        url: '/admin/japanese_v3/radioLibOrder',
        method: 'put',
        data
    })
}